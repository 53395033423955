import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import style from "./nav-bar.module.css"
import { AuthContext } from "../context/GlobalState"

const Nav = () => {
  const { currUser, isLoading, logoutUser } = useContext(AuthContext)

  // React.useEffect(() => {
  //   console.log("Nav waiting for User")
  //   waitForUser(user => {
  //     setSignedIn(!!user)
  //     setDoneLoading(true)
  //     console.log("Nav got user", user)
  //   })

  // }, [])

  return (
    <div className={style.container}>
      <h2>Spent</h2>
      <nav
        className={[style.navItems, isLoading ? style.loading : ""].join(" ")}
      >
        {!currUser && (
          <Link to="/" activeStyle={{ color: "#000" }}>
            About
          </Link>
        )}
        {!!currUser && (
          <Link to="/app/dashboard" activeStyle={{ color: "#000" }}>
            Dashboard
          </Link>
        )}
        {!!currUser ? (
          <a
            href="/"
            onClick={event => {
              event.preventDefault()
              logoutUser()
              navigate(`/app/login`)
            }}
          >
            Logout
          </a>
        ) : (
          <Link to="/app/login" activeStyle={{ color: "#000" }}>
            Sign In
          </Link>
        )}
      </nav>
    </div>
  )
}

export default Nav
