import React, { createContext, useEffect, useState, useReducer } from "react"
import { waitForUser, handleLogin, logout } from "../services/auth"

// create context
export const AuthContext = createContext()

// Provider component
export const AuthProvider = ({ children }) => {
  const [currUser, setCurrUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [userCreated, setUserCreated] = useState(true)

  useEffect(() => {
    return waitForUser(user => {
      console.log("AuthProvider -> waitForUser")
      
        setCurrUser(user)
        setIsLoading(false)
      
    })
  }, [])

  function initUser() {
    setUserCreated(false)
    handleLogin()
  }

  function logoutUser() {
    logout()
  }

  return (
    <AuthContext.Provider
      value={{
        currUser,
        isLoading,
        initUser,
        logoutUser,
        userCreated,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
