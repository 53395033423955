import React from "react"
import NavBar from "./nav-bar"
import style from "./layout.module.css"
const Layout = ({ children }) => (
  <div className={style.container}>
    <NavBar />
    <div className={style.body}>{children}</div>
  </div>
)
export default Layout
