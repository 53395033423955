// import * as firebase from "firebase/app"
// // Add the Firebase services that you want to use
// import "firebase/auth";
// import "firebase/firestore";

const firebase = typeof window !== `undefined` ? require("firebase/app") : null
if (typeof window !== `undefined`) {
  require("firebase/auth")
  require("firebase/firestore")
}

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyCLRVAkHcBwLURyVylEdlm1SeRSMZzhxtU",
//   authDomain: "spent-3b310.firebaseapp.com",
//   databaseURL: "https://spent-3b310.firebaseio.com",
//   projectId: "spent-3b310",
//   storageBucket: "spent-3b310.appspot.com",
//   messagingSenderId: "269285983487",
//   appId: "1:269285983487:web:7c0445a55aeeeb90d038ca",
//   measurementId: "G-2BSF250005",
// })

const config = {
  apiKey: "AIzaSyCLRVAkHcBwLURyVylEdlm1SeRSMZzhxtU",
  authDomain: "spent-3b310.firebaseapp.com",
  databaseURL: "https://spent-3b310.firebaseio.com",
  projectId: "spent-3b310",
  storageBucket: "spent-3b310.appspot.com",
  messagingSenderId: "269285983487",
  appId: "1:269285983487:web:7c0445a55aeeeb90d038ca",
  measurementId: "G-2BSF250005",
}



export default firebase ? firebase.initializeApp(config) : null // !firebase.app.length ? firebase.initializeApp(config) : firebase.app();

// let firebaseInstance = null

// export const getFirebase = firebase => {
//   if (firebaseInstance) {
//     return firebaseInstance
//   }

//   firebase.initializeApp(config)
//   firebaseInstance = firebase

//   return firebase
// }
