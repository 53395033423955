import { storeUser } from "./firestore"
import app from "./base"
import * as firebase from "firebase/app"

export const isBrowser = () => typeof window !== "undefined"

export const waitForUser = callback => {
  if (!isBrowser()) {
    callback(null)
  }else{
    return app.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        callback(user)
      } else {
        // No user is signed in.
        callback(null)
      }
    })
  }
  
}

export const handleLogin = () => {
  if (!isBrowser()) return

  var provider = new firebase.auth.GoogleAuthProvider()
  //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  app.auth().useDeviceLanguage()
  app.auth()
    .signInWithPopup(provider)
    .then(result => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken
      // The signed-in user info.
      var user = result.user
      // Write new user to database
      storeUser(user)
      return true
    })
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code
      var errorMessage = error.message
      // The email of the user's account used.
      var email = error.email
      // The app.auth.AuthCredential type that was used.
      var credential = error.credential
      // ...
      return false
    })
}

export const logout = callback => {
  app.auth()
    .signOut()
    .then(function() {
      // Sign-out successful.
      console.log("Successfully signed out.")
    })
    .catch(function(error) {
      // An error happened.
      console.log("Error in signed out.", error)
    })
  //callback()
}
