import moment from "moment"
import app from "./base"


export const createTransaction = (currUser, { text, amount }) => {
  // write to db
  if (!!!currUser) return
  // getUserDocID(currUser.email, userID => {
  app
    .firestore()
    .collection("users")
    .doc(currUser.uid)
    .collection("transactions")
    .add({
      text,
      amount,
      dateCreated: new Date(),
    })
    .then(function() {
      console.log("Document successfully written!")
    })
  // })
}

export const deleteTransaction = (currUser, dateCreated) => {
  if (!!!currUser) return
  // getUserDocID(currUser.email, userID => {
  app
    .firestore()
    .collection("users")
    .doc(currUser.uid)
    .collection("transactions")
    .where("dateCreated", "==", dateCreated)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref
          .delete()
          .then(() => {
            console.log("Document successfully deleted!")
            //callback()
          })
          .catch(function(error) {
            console.error("Error removing document: ", error)
          })
      })
    })
  // })
}

export const getTransactions = (currUser, range, callback) => {
  // get all transactions from curr user
  // make sure user is not null
  // console.log("MOMENT -- ",moment.utc().startOf('month').unix())
  console.log("Range in Get: ",range);
  let today = new Date()
  let startOfMonth, endOfMonth;
  if (range) {
     startOfMonth = new Date(range.startDate)
     endOfMonth = new Date(range.endDate)
  } else {
     startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
     endOfMonth = new Date(
      today.getFullYear(),
      today.getMonth()+1,
      0
    )
  }

  console.log("start: ", startOfMonth)
  console.log("end: ", endOfMonth)
  if (!!!currUser) return
  // get userID from user email
  console.log(currUser.uid)
  // getUserDocID(currUser.email, userID => {
  app
    .firestore()
    .collection("users")
    .doc(currUser.uid)
    .collection("transactions")
    .where("dateCreated", ">=", startOfMonth)
    .where("dateCreated", "<=", endOfMonth)
    .orderBy("dateCreated", "desc")
    .onSnapshot(
      querySnapshot => {
        console.log("Inside QuerySnapshot..")
        let transactions = []
        querySnapshot.forEach(doc => {
          transactions.push(doc.data())
        })
        transactions.forEach((elem, i) => (elem.key = i))
        callback(transactions)
      },
      error => {
        console.log(error)
      }
    )
  // })
}

export const storeUser = user => {
  console.log("Firestore -> StoreUser")
  // check if user already exists
  // console.log("new email: ", user.email)

  app
    .firestore()
    .collection("users")
    .doc(user.uid)
    .set(
      {
        fullname: user.displayName,
        email: user.email,
        dateSignedIn: new Date(),
      },
      { merge: true }
    )
    .then(() => {
      console.log("Document successfully written!")
      // Set loading isLoading -> false so that privateRoute can show dashboard
    })

  // app
  //   .firestore()
  //   .collection("users")
  //   .where("email", "==", user.email)
  //   .get()
  //   .then(querySnapshot => {
  //     if (!querySnapshot.empty) {
  //       // user already exists
  //       // TODO: update last signed in timestamp
  //       console.log("-- User exists")
  //     } else {
  //       // user does not exist, write to db
  //       console.log("-- User does not exists, writing to db...")

  //     }
  //   })
  //   .catch(function(error) {
  //     console.log("Error getting documents: ", error)
  //   })
}
